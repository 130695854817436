import { axiosWithAuth } from '@/apis/base';
import { ComsUserForm } from '@/pages/user/UserDetail';
import { Authority, ComsUserStatus } from '@/constants';
import { ComsUser } from '@/models/comsUser';
import { AxiosResponse } from 'axios';

interface ComsUserList {
  page: number;
  pageSize: number;
  keyword: string;
  authority: Authority;
  comsUserStatus: ComsUserStatus;
}

export const getComsUsers = ({
  page,
  pageSize,
  keyword,
  authority,
  comsUserStatus,
}: ComsUserList) => {
  return axiosWithAuth.get(
    `/user/?page=${page}&size=${pageSize}&authority=${authority}
    &comsUserStatus=${comsUserStatus}&keyword=${keyword}`
  );
};

export const getComsUser = (uid: string) => {
  return axiosWithAuth.get(`/user/${uid}`);
};

export const editComsUser = (
  formData: ComsUserForm
): Promise<AxiosResponse<ComsUser>> => {
  return axiosWithAuth.patch(`/user/${formData.uid}`, formData);
};

export const getUserNewJoinCount = () => {
  return axiosWithAuth.get(`/statistics/user/new-join-count`);
};

export const deleteUser = (uid: string) => {
  return axiosWithAuth.delete(`/user/${uid}`);
};
